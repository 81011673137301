import React, { Component , Fragment } from "react";
import { Parallax } from "react-parallax";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";
import CounterOne from "../elements/counters/CounterOne";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import ServiceList from "../elements/service/ServiceList";
import Helmet2 from "../component/common/Helmet";


const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'Izrada poslovnih web i mobilnih aplikacija',
        description: 'Poslovne web aplikacije su naš osnovni proizvod. Jednostavne, lake za korišćenje, intuitivne, brze, sigurne i pouzdane. Stalno u kontaktu sa našim korisnicima, trudimo se da budemo od pomoći i čujemo svakog ko ima šta da nam kaže.  ',
        buttonText: 'O nama',
        buttonLink: '/about'
    }
]

    const PortfolioList = [
    {
        image: 'image-2',
        category: 'Fakulteti',
        title: 'Fakultis',
        subtitle: 'Informacioni sistem fakulteta',
        link:'/fakultis'  
    },
    {
        image: 'image-8',
        category: 'Fakulteti',
        title: 'e-Nastavnik',
        subtitle: 'Nastavnički portal',
        link:'/nasport'
    },
    {
        image: 'image-eindeks',
        category: 'Fakulteti',
        title: 'e-Indeks',
        subtitle: 'Studentski portal',
        link:'/eindeks'
    },
	{
        image: 'image-5',
        category: 'Fakulteti',
        title: 'e-Upitnik',
        subtitle: 'Anketiranje i samoevaluacija',
        link:'/upitnik'
    },
	{
        image: 'image-4',
        category: 'Fakulteti',
        title: 'e-Upis',
        subtitle: 'Portal za upis',
        link:'/upis'
    },
  
]


class Paralax extends Component{ 
    render(){
        const PostList = BlogContent.slice(0 , 3);
        // const ServiceContent = ServiceList.slice(0 , 3);
        return(
            <Fragment>
                <Helmet2 pageTitle="" 
                pageDescription="Poslovne web aplikacije su naš osnovni proizvod. Jednostavne, lake za korišćenje, intuitivne, brze, sigurne i pouzdane. Stalno u kontaktu sa našim korisnicima, trudimo se da budemo od pomoći i čujemo svakog ko ima šta da nam kaže." 
                metaTitle=">Stanković Soft — Agencija za promet i izradu softvera i druge usluge"
                keywords="poslovanja,stankovicsoft,stankovic,razvoj web aplikacija,aplikacija,software,softver,izvršitelji,izvršitelj,teretane,program,upravljanje,evidencija,studenti,student,studenata,web,web aplikacije,studentski portal,nastavnicki portal, eindeks,mindeks,e-indeks,e indeks,e-upis,e upis,e-upitnik,e upitnik,e-nastavnik,e nastavnik,informacioni sistem,univerzitet,univerziteti,indeks,m-indeks,m indeks,upitnik,elektronska prijava ispita,upis,elektronski upis,falultis,fakulteti,fakultet,služba,sluzba,cronus,gym,gymgym,"
                />
            

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="stankoviclogo.png" />
					
                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value , index) => (
                        <div className="slide slide-style-2 slider-video-bg bg_image bg_image--front d-flex align-items-center justify-content-center" key={index} data-black-overlay="6">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            {value.title ? <h1 className="title theme-gradient">{value.title}</h1> : ''}
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                            {<div className="slide-btn"><a className="rn-button-style--2 btn-danger" href="https://uns.blokada.info/">Blokada INFO</a></div>}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    ))}
                    </div>
                    {/* End Single Slide */}


                {/* Start CounterUp Area */}
                <Parallax className="rn-counterup-area rn-paralax-counterup" strength={1000} style={{backgroundColor:'black'}}>
                    <div className="counterup-area ptb--120">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title service-style--2 text-center mb--30 mb_sm--0">
                                        <h2 className="title theme-gradient">Poslovanja</h2>
                                    </div>
                                </div>
                            </div>
                            <CounterOne />
                        </div>
                    </div>
                </Parallax>
                {/* End CounterUp Area */}


                



                {/* Start Service Area  */}
                <Parallax className="rn-paralax-portfolio" strength={1000}  style={{backgroundImage: 'url(' + '/assets/images/bg/bg-home.jpg' + ')', backgroundAttachment: 'fixed', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                    <div className="portfolio-area ptb--120" data-black-overlay="6">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title service-style--2 text-center mb--30 mb_sm--0">
                                        <h2 className="title theme-gradient">Naše aplikacije</h2>
                                        <p>Napravili smo sisteme aplikacija koji skladno fukncionišu, uz minimalnu ili nikakvu obuku, omogućavaju elektronsko praćenje i obavljanje kompletnog poslovanja klijenata.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {/* Start Single Portfolio  */}
                                {PortfolioList.map((value , index) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--40" key={index}>
                                        <a href={`${value.link}`}>
										<div className="portfolio">
                                            <div className="thumbnail-inner">
                                                <div className={`thumbnail ${value.image}`}></div>
                                                <div className={`bg-blr-image ${value.image}`}></div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p>{value.category}</p>
                                                    <h4 style={{color:'white'}}>{value.title}</h4>
                                                    <h5 style={{color:'white'}}>{value.subtitle}</h5>
                                                    <div className="portfolio-button">
                                                        <a className="rn-btn" href={`${value.link}`}>Detalji</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
										</a>
                                    </div>
                                ))}
                                {/* End Single Portfolio  */}
                            </div>
							<div className="row align-items-center">
                                <div className="col-lg-12">
                                    <div className="ostalo" style={{textAlign:'center', marginTop: 50}}>
                                        <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href="portfolio">Ostale aplikacije</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Parallax>
                {/* End Service Area  */}

                {/* Start Service Area  */}
                <Parallax className="rn-paralax-service" strength={1000}  style={{background:'linear-gradient(#000, #343a40)'}}>
                    <div className="service-area ptb--120">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-12">
                                    <div className="section-title service-style--2 text-center mb--30 mb_sm--0">
                                        <h2 className="title theme-gradient">Šta pravi razliku?</h2>
                                        <p>Potrebna Vam je poslovna aplikacija koja je u potpunosti prilagođena Vašim potrebama. Želite jednostavno i lako korišćenje, osiguranu bezbednost podataka, kao i sisteme za laku i jednostavnu nadogradnju i održavanje. Kroz sledeće procese obezbedili smo da su naši klijenti zadovoljni proizvodom:</p>
                                    </div>
                                </div>
                            </div>
                            <ServiceList item="6" column="col-lg-4 col-md-6 col-sm-6 col-12" />
                        </div>
                    </div>
                </Parallax>
                {/* End Service Area  */}
               


                {/*                    
                 *Start Testimonial Area *
                <Parallax className="rn-testimonial-area rn-paralax-testimonial  rn-testimonial-light" bgImage={image7} strength={500} data-black-overlay="5">
                    <div className="testimonial-area ptb--120" data-black-overlay="6">
                        <div className="container">
                            <Testimonial />
                        </div>
                    </div>
                </Parallax>
                * End Testimonial Area *
                */}


                {/* Start Brand Area */}
                <Parallax className="rn-brand-area brand-separation ptb--120" strength={1000} style={{backgroundImage: 'url(' + '/assets/images/bg/bg-clients.jpg' + ')', backgroundAttachment: 'fixed', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                    <div className="brand-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <BrandTwo />
                                </div>
                            </div>
                        </div>
                    </div>

                </Parallax>
                {/* End Brand Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </Fragment>
        )
    }
}
  
export default Paralax;
